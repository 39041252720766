import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { DOMAIN_TEXT } from "~/core/constants/Domains";
import ObjectItem from "./ObjectItem";


/**
 * Компонет для отображения ключевой инфорации записи журнала изменений
 */
const Info = observer(({ item, className }) => {
  const content = useMemo(() => {
    const res = [];
    const match = item.representation.match(/{\w+}/gi);
    let nextStart = 0;
    let index = 0;
    let paramName;
    const text = item.representation;
    do {
      paramName = match[index];
      const paramNameIndex = text.indexOf(paramName);
      if (paramName) {
        const before = text.substring(nextStart, paramNameIndex);
        if (before) {
          res.push(<div key={index} className="simple-text">{before}</div>);
        }

        const obj = item.paramsMap.get(paramName);
        if (!obj) {
          res.push(paramName);
        } else {
          if (Array.isArray(obj)) {
            const firstObj = obj[0];
            // если пришел текстовый элемент, то отобрааем только первый элемент,
            // тк следующие вложения - внутреннее наполнение
            if (firstObj && firstObj.domain === DOMAIN_TEXT) {
              item && res.push(
                <ObjectItem journalItem={item} paramName={paramName} object={firstObj} />
              );
            } else {
              // eslint-disable-next-line no-loop-func
              obj.forEach((o) => {
                if (o) {
                  res.push(
                    <ObjectItem journalItem={item} paramName={paramName} object={o} />
                  );
                }
              });
            }
          } else {
            item && res.push(
              <ObjectItem journalItem={item} paramName={paramName} object={obj} />
            );
          }
        }
        
        nextStart = paramNameIndex + paramName.length;
        index += 1;
      }
    } while (paramName);

    if (nextStart) {
      const after = text.substr(nextStart);
      
      if (after) {
        res.push(<div key={index} className="simple-text">{after}</div>);
      }
    }
    return res.length ? res : text;
  }, [item && item.representation, item && item.paramsMap && item.paramsMap.size]);

  return (
    <div className={`journal-item-info ${className ? className : ""}`}>
      <div className="journal-item-info-body">
        <div className="text">
          {content }
        </div>
      </div>
    </div>
  );
});

Info.propTypes = {
  item:      PropTypes.object,
  className: PropTypes.string
};


export default Info;
