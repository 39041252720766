import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import { observer } from "mobx-react";
import AisObject from "~/core/components/AisObject";
import SchemaBoundaryModel from "../../models/schemaBoundary";

/**
 * Компонент для отображения принесенного объекат АИС
 * 
 * @param {Object} props набор параметров
 * @param {SchemaBoundaryModel} props.boundary  запись ограничения Доли в схеме отчетов о трассировке
 */
const ObjectItem = observer(({ uid, boundary, object }) => {
  const onRemoveObject = useCallback(() => {
    boundary.removeValue(uid);
  }, [uid, boundary]);

  if (boundary.value) {
    return (
      <div 
        key={object.uid || object.id}
        className="object-wrapper"
      >
        <AisObject object={object} icon={object.icon} withVersion={true} />
        <div className="object-remove" onClick={onRemoveObject}>
          <Components.Tooltip
            content="Удалить"
          >
            <Components.Icon icon="close-M" />
          </Components.Tooltip>
        </div>
      </div>    
    );
  }
});

ObjectItem.propTypes = {
  uid:      PropTypes.string.isRequired,
  boundary: PropTypes.instanceOf(SchemaBoundaryModel).isRequired,
  object:   PropTypes.object.isRequired
};


export default ObjectItem;
