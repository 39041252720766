import { observer } from "mobx-react";
import React, { Fragment, useMemo } from "react";

const TableRow = observer(({ data, renderItem }) => {
  const { itemsForRender, idsArray, childrenLength } = data;

  const itemsRender = useMemo(() => {
    const items = itemsForRender.map((itemData) => {
      return renderItem(itemData);
    });
    if (data.missingItemsCount > 0) {
      items.push(<div
        className="table-cell-skeleton" style={{
          gridColumn: `span ${data.missingItemsCount}`
        }}
      ></div>);
    }
    return items;
  }, [idsArray, itemsForRender, data.missingItemsCount]);

  if (!childrenLength) {
    return null;
  }

  return <Fragment>{itemsRender}</Fragment>;
});

export default TableRow;
