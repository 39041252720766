import { observer } from "mobx-react";
import React, { useCallback, useMemo, useLayoutEffect, useRef } from "react";
import PlusMenuItem from "./PlusMenuItem";
import { CLS_TEXT_PICTURE } from "../../../core/constants/Classes";

const PlusMenu = observer(({ data, style }) => {
  const { plusMenuItems, isExpanded, isVersion } = data;

  const onItemClick = useCallback(
    async(item, kind) => {
      data.setExpanded(false);
      const newItem = await data.createItem(item, kind);
      if (newItem.className === CLS_TEXT_PICTURE) {
        newItem.setEditingPicture(true, true);
      }
    },
    [data]
  );

  const elementRef = useRef();

  useLayoutEffect(() => {
    if (isExpanded && elementRef && elementRef.current) {
      const rec = elementRef.current.getBoundingClientRect();
      if (
        rec.top < 0 ||
        rec.bottom >
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        elementRef.current.scrollIntoView({
          block:    "center",
          behavior: "smooth"
        });
        setTimeout(() => {
          data.setExpanded(true);
        }, 300);
      }
    }
  }, [isExpanded, elementRef]);

  const menuItems = useMemo(() => {
    if (!isExpanded) {
      return [];
    }
    return plusMenuItems.map((item, i) => {
      return (
        <PlusMenuItem
          key={`${item.class}-${i}`}
          item={item}
          onItemClick={onItemClick}
        />
      );
    });
  }, [plusMenuItems, isExpanded]);

  const hasItems = useMemo(() => {
    let size = 0;
    plusMenuItems.forEach((item) => {
      if (item && item.availableKindsArray && item.availableKindsArray.length) {
        size += item.availableKindsArray.length;
      }
    });
    return !!size;
  }, [plusMenuItems]);

  if (isVersion || !plusMenuItems || !hasItems) {
    return null;
  }

  return (
    <div ref={elementRef} style={style} className="plus-holder">
      <div className={`menu-items-holder ${isExpanded ? "expanded" : ""}`}>
        {isExpanded && menuItems}
      </div>
    </div>
  );
});

export default PlusMenu;
