import React from "react";
import PropTypes from "prop-types";
import Diff from "text-diff";

/**
 * Компонент для отображения разницы между старым и новым значением описания
 * 
 * @param {Object} props набор параметров
 * @param {String} params.oldValue старое значение 
 * @param {String} params.newValue новое значение 
 * @param {String} params.className пользовательский className
 */
const DiffText = ({ oldValue = "", newValue = "", className }) => {
  const diff = new Diff();
  const textDiff = diff.main(oldValue, newValue);
  diff.cleanupSemantic(textDiff);
  return (
    <div className={`journal-item-info-text ${className ? className : ""}`}>
      {textDiff.map(([op, text], i) => {
        switch (op) {
          case 1:
            return <ins key={i}>{text}</ins>;
          case -1:
            return <del key={i}>{text}</del>;
          case 0:
            return <span key={i}>{text}</span>;
        }
        return <span key={i}>{text}</span>;
      })}
    </div>
  );
};

DiffText.propTypes = {
  oldValue:  PropTypes.string, 
  newValue:  PropTypes.string, 
  className: PropTypes.string
};

export default DiffText;
