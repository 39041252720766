import { observable, computed, action } from "mobx";
import { v4 as uuid } from "uuid";
import { getDomainByClass } from "~/core/utils";
import { CLS_KIND, CLS_TRACER_BELONGS_TO, CLS_TRACER_CONTAINS, getClassTitle } from "~/core/constants/Classes";


const tracerTypes = [CLS_TRACER_BELONGS_TO, CLS_TRACER_CONTAINS];

/**
 * Модель записи ограничения Доли в схеме отчетов о трассировке
 *
 * @class  SchemaBoundaryModel
 */
class SchemaBoundaryModel {
  /**
   * uid ограничения
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * Набор ограничений
   *
   * @type Array<Object>
   */
  @observable
  items = [];

  /**
   * Пользовательские значениz
   *
   * @type Map
   */
  @observable
  valuesMap = new Map();

  /**
   * Cоздание модели
   *
   * @param {Array<Object>} items набор ограничений
   * @param {Object} parameters описание переменных в  наборе ограничений
   *
   * @retrun {SchemaBoundaryModel}
   */
  static create(items, parameters, rootStore) {
    return new SchemaBoundaryModel(items, parameters, rootStore);
  }

  constructor(items = [], parameters, rootStore) {
    this.uid = uuid();
    this.items = items;
    this.parameters = parameters;
    this.rootStore = rootStore;
  }

  /**
   * Строковое представление
   *
   * @return {String}
   */
  @computed
  get title() {
    return getClassTitle(this.type);
  }

  /**
   * Тип ограничения
   *
   * @return {String}
   */
  @computed
  get type() {
    const item = this.items.filter((item) => {
      return item && item.class && tracerTypes.includes(item.class);
    })[0];

    return item && item.class;
  }

  /**
   * Объект ограничения. Он мб выставлен уже изначально в самой схеме или же задан пользователем, через параметр value
   *http://staging.ncc.ais3p.lan:8080/trace-schemas/1848db4304294d72bd75ce8fd33c845f
   * @return {Object}
   */
  @computed
  get object() {
    if (this.value) {
      return this.value;
    }
    const item = this.items.filter((item) => {
      return item && !(typeof item === "string" || item instanceof String) && 
      item.class && !tracerTypes.includes(item.class);
    })[0];

    if (item) {
      const uid = item.uid || item.id;
      
      let obj = item;
      if (item.class === CLS_KIND) {
        const kind = this.rootStore.kindsStore.getKind(uid);
        const icon = kind && this.rootStore.iconString({ uid: kind.uid });
        obj = {
          uid,
          icon,
          name:    kind && kind.name,
          class:   item.class,
          version: item.version
        };
      } else {
        const domain = getDomainByClass(item.class);
        obj = this.rootStore.objectStore.getVersion(uid, domain, item.version);
      }
      
      
      return obj || item;
    }
    
    return item;
  }

  /**
   * Название переменной, которая используется в параметрах
   * Название переменной должно начинаться с символа - $
   * 
   * @return {String}
   */
  @computed
  get name() {
    return this.items.filter((item) => {
      return item && (typeof item === "string" || item instanceof String) && item.indexOf("$") === 0;
    })[0];
  }

  /**
   * Описание (параметры) переменной
   * 
   * @return {Object}
   */
  @computed
  get parameter() {
    return this.name && this.parameters[this.name];
  }

  /**
   * Список значений, выставленных пользователем
   * 
   * @return {Array<Object>}
   */
  @computed
  get value() {
    if (this.valuesMap.size > 0) {
      return Array.from(this.valuesMap.values());
    }

    return undefined;
  } 

  /**
   * Задать пользовательское значение объекта
   * 
   * @param {Object} value
   */
  @action
  setValue(value) {
    if (value) {
      const uid = value.uid || value.id;
      this.valuesMap.set(uid, value);
    } else {
      this.valuesMap.clear();  
    }
  }

  @action
  removeValue(uid) {
    this.valuesMap.delete(uid);
  }

  /**
   * Флаг, указывающий, что параметры для ограничения выставлены
   * 
   * @return {Boolean}
   */
  @computed
  get isValid() {
    if (this.name) {
      return !!this.value;  
    }

    return true;
  }

  /**
   * Сбросить пользовательское значение объекта
   * 
   * @param {Object} value
   */
  @action
  reset() {
    // this.value = undefined;
    this.valuesMap.clear();  
  }
}

export default SchemaBoundaryModel;
