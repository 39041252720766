import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import AisObject from "~/core/components/AisObject";
import DiffText from "./DiffText";
import { 
  CLS_TEXT_CHUNK_VARCHAR,
  CLS_TEXT_CHUNK_INLINE_CODE, 
  CLS_TEXT_CHUNK_INLINE_FORMULA, 
  CLS_TEXT_CHUNK_INLINE_PICTURE 
} from "~/core/constants/Classes";
import { DOMAIN_TEXT } from "~/core/constants/Domains";
import InlinePicture from "~/modules/newText/components/InlinePicture";
import InlineFormula from "~/modules/newText/components/InlineFormula";


/**
 * Компонет для отображения ключевой инфорации записи журнала изменений
 */
const ObjectItem = observer(({ journalItem, object, paramName }) => {
  const oldObject = useMemo(() => {
    // Регулярное выражение для поиска любых строк в фигурных скобках
    const regex = /({[^}]+})/g;

    // Функция для добавления префикса old_
    const replaceWithPrefix = (match) => {
      return `{old_${match.substring(1, match.length - 1)}}`;
    };

    // Замена всех вхождений
    const oldParamName = paramName.replace(regex, replaceWithPrefix);

    const obj = journalItem.paramsMap.get(oldParamName);
    if (Array.isArray(obj)) {
      const firstObj = obj[0];
      // если пришел текстовый элемент, то отобрааем только первый элемент,
      // тк следующие вложения - внутреннее наполнение
      if (firstObj && firstObj.domain === DOMAIN_TEXT) {
        return firstObj;
      }
    }
    return obj;
  }, [paramName, journalItem && journalItem.paramsMap && journalItem.paramsMap.size]);

  const renderValue = useCallback(() => {
    if ([CLS_TEXT_CHUNK_INLINE_CODE,
      CLS_TEXT_CHUNK_INLINE_FORMULA, 
      CLS_TEXT_CHUNK_INLINE_PICTURE,
      CLS_TEXT_CHUNK_VARCHAR
    ].includes(object?.className)) {
      switch (object?.className) {
        case CLS_TEXT_CHUNK_INLINE_PICTURE: 
          return <InlinePicture data={object} readOnly={true} />;
        case CLS_TEXT_CHUNK_INLINE_FORMULA:
          return <InlineFormula data={object} />;
        case CLS_TEXT_CHUNK_VARCHAR: {
          return <DiffText oldValue={oldObject?.value} newValue={object?.value} />;
        }
        default:
          return object?.value;
      }
    } 

    return object?.title || object?.name;
  }, [
    object, 
    object?.className, object?.title, object?.name, object?.value, 
    oldObject, oldObject?.value
  ]);

  return (
    <AisObject 
      key={`${object.uid}-${paramName}`} 
      object={object} 
      renderValue={renderValue}
    />
  );
});

ObjectItem.propTypes = {
  jornalItem: PropTypes.object,
  object:     PropTypes.object,
  paramName:  PropTypes.string
};


export default ObjectItem;
