import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import Cropper from "react-cropper";
import DropdownButton from "~/core/components/Dropdown/DropdownButton";

import "cropperjs/dist/cropper.css";
import "./css/image-editor.scss";

const ZOOM_STEP = 0.1;
const ROTATE_DEGREE = 45;

/**
 * Редактор изображения
 * Позволяет выделить необходимую область и повернуть изображение
 * 
 * @param {String} src url исходного файла
 * @param {Boolean} isProcessing признак, что идет обработка или сохранение изображения
 * @param {Function} onChange callback ф-я на сделанные изменения. В параметрах передается Blob исправленного 
 * изображения
 * @returns 
 */
const ImageEditor = ({ src: initialSrc, isProcessing, onChange  }) => {
  const cropperRef = useRef(null);
  const [dragMode, setDragMode] = useState("move");
  const [isModified, setIsModified] = useState(false);
  const [src, setSrc] = useState(initialSrc);
  const [isCropped, setIsCropped] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [currentZoomRatio, setCurrentZoomRatio] = useState(1);

  /**
   * Получить временный url исправленного изображения для предпросмотра
   * 
   * @param {Boolean} withZoomSize признак того, что исправленно изображение должно получиться с учетом
   * масшатирования (Zoom) или только в натуральный размер @default false
   * 
   * 
   * @returns {String} временный url исправленного изображения для предпросмотра 
   */
  const generateNewImgSrc = (withZoomSize = false) => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const { width, height } = cropper.getCropBoxData();
      return cropper.getCroppedCanvas(withZoomSize ? {
        width, 
        height 
      } : {}).toDataURL();
    } else {
      return initialSrc;
    }
  };

  /**
   * Получить Blob исправленного изображения для выгрузки на сервер
   * 
   * @param {Boolean} withZoomSize признак того, что исправленно изображение должно получиться с учетом
   * масшатирования (Zoom) или только в натуральный размер @default false
   * 
   * @returns {Blob} blob исправленного изображения для выгрузки
   */
  const generateNewImgBlob = (withZoomSize = false) => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const { width, height } = cropper.getCropBoxData();
      return cropper.getCroppedCanvas(withZoomSize ? {
        width, 
        height 
      } : {}).toBlob((blob) => {
        onChange && onChange(blob);
      });
    } else {
      return initialSrc;
    }
  };

  /**
   * Сделать обрезку изображения
   */
  const doCrop = useCallback((e) => {
    const { type } = e;
    
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      setIsCropped(true);
      setSrc(generateNewImgSrc(type === "zoom"));
      generateNewImgBlob(type === "zoom");
    }
  }, [cropperRef && cropperRef.current, onChange]);

  /**
   * Отменить выделение 
   */
  const onCancelCrop = useCallback(() => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const { rotate } = cropper.getData();
      cropper.clear();
      setIsModified(rotate !== 0);
      setIsCropping(false);
    }
  }, [cropperRef && cropperRef.current]);

  /**
   * Сбросить сделанные изменения с ихображением
   */
  const onReset = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.clear();
      cropper.reset();
      setSrc(initialSrc);
      setIsCropped(false);
      setIsCropping(false);
    }
    initCanvas();
    setIsModified(false);
    onChange && onChange(null);
  }, [cropperRef && cropperRef.current, initialSrc, dragMode, onChange]);

  /**
   * Переключиться в режим перемещения изображения
   */
  const onMoveMode = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.setDragMode("move");
      setDragMode("move");
    }
  }, [cropperRef && cropperRef.current]);

  /**
   * Переключиться в режим выделения области у изображения
   */
  const onCropMode = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.setDragMode("crop");
      setDragMode("crop");
    }
  }, [cropperRef && cropperRef.current]);

  /**
   * Увеличить изображение
   */
  const onZoomIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.zoom(ZOOM_STEP);
    }
  }, [cropperRef && cropperRef.current]);

  /**
   * Уменьшить изображение
   */
  const onZoomOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.zoom(ZOOM_STEP * -1);
    }
  }, [cropperRef && cropperRef.current]);

  /**
   * Перевернуть изображение влево на 45%
   */
  const onRotateLeft = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.rotate(ROTATE_DEGREE * -1);
      setIsModified(true);
      // generateNewImgBlob();
    }
  }, [cropperRef && cropperRef.current, onChange]);

  /**
   * Перевернуть изображение вправо на 45%
   */
  const onRotateRight = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.rotate(ROTATE_DEGREE);
      setIsModified(true);
      // generateNewImgBlob();
    }
  }, [cropperRef && cropperRef.current, onChange]);

  /**
   * Обработчик события на zoom
   */
  const onZoom = useCallback((e) => {
    const { detail } = e;
    setCurrentZoomRatio(detail.ratio);
  }, []);

  /**
   * Обработчик события начало выделения участка изображения
   */
  const onCrop = useCallback(({ detail }) => {
    // Данное событие срабатывает так же и на инициализацию режима выделения.
    // Чтобы его обрабатывать при инициализации, а только при выделении, нужно проверить детали
    // выделения 
    if (detail.width > 0 && detail.height > 0) {
      setIsModified(true);
      setIsCropping(true);
    }
  }, []);

  /**
   * Обработчик события начала работы компоненты Cropper
   */
  const onReady = useCallback(() => {
    initCanvas();
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.setDragMode(dragMode);
    }
  }, [initialSrc, src, isCropped, dragMode]);

  /**
   * Привести рисунок к исходному размеру. 
   * Компонент Croper растягивает небольшой рисунок на все пространство. 
   * Чтобы привести изображение к исходному виду, приходится делать масштабирование
   * @returns 
   */
  const initCanvas = useCallback(() => {
    const cropper = cropperRef.current?.cropper;
    if (!cropper) {
      return;
    }

    const { width, naturalWidth } = cropper.getCanvasData();
    if (naturalWidth < width) {
      cropper.zoomTo(1);
      cropper.crop();
    }
  }, [isCropped, src, initialSrc]);

  const toolbarLeftBtns = useMemo(() => {
    if (isCropped) {
      return [
        <Components.Button
          key="reset"
          icon="change-M"
          tooltip="Отменить сделанные изменения"
          text="Отменить изменения"
          isDisabled={isProcessing}
          onPress={onReset}
        />
      ];
    }

    const buttons = [];
    if (currentZoomRatio === 1) {
      buttons.push(
        <Components.Button
          key="crop"
          icon="ok-M"
          tooltip="Вырезать выделенную область"
          text="Вырезать"
          isDisabled={!isCropping || isProcessing}
          isLoading={isProcessing}
          onPress={doCrop}
        />
      );
    } else {
      buttons.push(
        <DropdownButton 
          key="crop"
          text="Вырезать"
          tooltip="Вырезать выделенную область"
          isDisabled={!isCropping || isProcessing}
          items={[{
            icon:  currentZoomRatio < 0 ? "zoom-out-M" : "zoom-in-M",
            title: "С учетом масштаба",
            type:  "zoom"
          }, {
            icon:  "ok-M",
            title: "Натуральный размер",
            type:  "original"
          }]}
          onClickItem={doCrop}
        />
      );
    }
    buttons.push(<Components.Spacer key="sp2" />);
    buttons.push(
      <Components.Button
        key="cancel"
        icon="cancel-M"
        tooltip="Отменить выделение"
        text="Отменить выделение"
        isDisabled={!isCropping || isProcessing}
        onPress={onCancelCrop}
      />
    );
    return buttons;
  }, [isModified, isProcessing, isCropped, isCropping, currentZoomRatio]);

  
  return (
    <div className="image-editor">
      <Components.ToolBar>
        {toolbarLeftBtns}
      </Components.ToolBar >
      {isProcessing && <Components.Preloader size={3} className="preloader-center" />}
      {!isCropped && 
        <React.Fragment>
          <Cropper
            src={src}
            className="cropper"
            // Cropper options
            viewMode={1}
            autoCrop={false}
            dragMode="move"
            background={false}
            modal={false}
            restore={false}
            guides={true}
            scalable={true}
            ref={cropperRef}
            crop={onCrop}
            zoom={onZoom}        
            ready={onReady}
          />
          <div className="image-editor-toolbar toolbar">
            <Components.Button
              icon="pick-M"
              tooltip="Режим перемещения изображения"
              color={dragMode === "move" ? "action" : ""}
              onPress={onMoveMode}
            />
            <Components.Button
              icon="cut-M"
              tooltip="Режим обрезки изображения"
              color={dragMode === "crop" ? "action" : ""}
              onPress={onCropMode}
            />
            <Components.Spacer />
            <Components.Button
              icon="zoom-in-M"
              tooltip="Увеличить изображение"
              onPress={onZoomIn}
            />
            <Components.Button
              icon="zoom-out-M"
              tooltip="Уменьшить изображение"
              onPress={onZoomOut}
            />
            <Components.Spacer />
            <Components.Button
              icon="rollback-M"
              tooltip="Повернуть влево изображение"
              onPress={onRotateLeft}
            />
            <Components.Button
              icon="refresh-M"
              tooltip="Повернуть вправо изображение"
              onPress={onRotateRight}
            />
          </div>
        </React.Fragment>
      }
      {isCropped &&
        <div className="image-editor-preview">
          <img src={src} alt="Изображение" />
        </div>
      }
    </div>
  );
};

ImageEditor.propTypes = {
  src:          PropTypes.string,
  isProcessing: PropTypes.bool,
  onChange:     PropTypes.func
};

export default ImageEditor;
