import getDomainByClass  from "./getDomainByClass";
import {
  DOMAIN_TEXT,
  DOMAIN_LIBRARY,
  DOMAIN_REPO,
  DOMAIN_ISSUE,
  DOMAIN_BINDER
} from "~/core/constants/Domains";
import {
  CLS_LIBRARY_REPOSITORY, 
  CLS_LIBRARY_TEXT_MATERIAL, 
  CLS_LIBRARY_TEXT_VERSION 
} from "~/core/constants/Classes";

import { generateRepoId } from "~/modules/repo/utils/uidHelpers";
import { DOMAIN_KINDS, DOMAIN_USERS } from "../constants/Domains";
import UserModel from "~/modules/users/models/User";
import KindModel from "~/modules/kindsAndAttrs/models/Kind";
import NodeItem from "~/modules/library/models/NodeItem";
import RepoNode from "~/modules/repo/models/RepoNode";
import RepoCodeObject from "~/modules/repo/models/CodeObject";
import { IssueModel } from "~/modules/issues/models";
import { Relation as RelationModel } from "~/modules/relations/models";
import TextStore from "~/modules/newText/stores/DataStore";
// import getPropsToOpenLayoutTool from "./layoutHelpers";

/**
 * Разобрать AisObject, полученный от сервиса #Composer и вернуть модель представления лоакльного объекта АИС
 * 
 * @param {Object} composerAisObject   AisObject, полученный от сервиса #Composer
 * @param {ObjectStore} objectStore глобавльное хранилище объектов
 * 
 * @returns {AisObject} модель представления лоакльного объекта АИС
 */
const processComposerAisObject = async(composerAisObject, objectStore) => {
  const { class:klass, version, representation = {}, fullObjectPath, members = [] } = composerAisObject;
  const domain = getDomainByClass(klass);
  let obj;
  switch (domain) {
    case DOMAIN_LIBRARY:{
      obj = await objectStore.processLibraryItem(representation, domain, {}, { loadKinds: false });
      break;
    }
    case DOMAIN_REPO: {
      obj = await objectStore.processRepositoryItem(representation, domain);
      break;
    }
    case DOMAIN_ISSUE:
      obj = await objectStore.processIssueItem(representation);
      break;
    case DOMAIN_TEXT:
      obj = await objectStore.processTextItem(representation, version, {}, { loadKinds: false });
      break;
    case DOMAIN_BINDER:
      obj = await objectStore.processRelationItem(representation);
      break;
    default:{
      // throw new Error(`No domain specified - ${domain} in class="${klass}"`);
      console.error(`Не определен домен - ${domain} в class="${klass}"`, composerAisObject);
    }      
  }
  obj?.setFullObjectPath(fullObjectPath);
  await objectStore.rootStore.kindsStore.processMembers(members, version);
  return obj;
};

/**
 * Разобрать AisObjectи вернуть модель представления лоакльного объекта АИС
 * 
 * @param {Object} aisObject   aisObject, полученный от сервиса 
 * @param {ObjectStore} objectStore глобальное хранилище объектов
 * @param {Boolean} addToObjectStore признак необходимости добавлять объект в objectStore.
 * Бывают случаи, когда такой объект не нужно добавлять в objectStore. Например при загрузке представления объектов для
 * Журнала изменений. Если добавлять такие объекты в objectStore, то старое представление объекта перетирает текущее. 
 * @param {DataStore} textStore хранилище для работы с тектовыми объектами. 
 * Данное хранилище необходимо для построения тектового представления отдельных объектов
 * 
 * @returns {AisObject} модель представления локального объекта АИС
 */
const processAisObject = async(aisObject, objectStore, addToObjectStore = true, textStore) => {
  const { class:klass, version } = aisObject;
  const domain = getDomainByClass(klass);
  let obj;

  switch (domain) {
    case DOMAIN_LIBRARY:{
      if (addToObjectStore) {
        obj = await objectStore.processLibraryItem(aisObject, domain, {}, { loadKinds: false });
      } else {
        obj = new NodeItem({ ...aisObject, objectStore });
      }
      break;
    }
    case DOMAIN_REPO: {
      if (addToObjectStore) {
        obj = await objectStore.processRepositoryItem(aisObject, domain);
      } else {
        if (aisObject.typeElement) {
          // у объекта кода есть тип элемента. У дерева репозитория такого признака нет
          obj = RepoCodeObject.create(aisObject, objectStore);
        }
        obj = RepoNode.create(aisObject, objectStore);
      }
      break;
    }
    case DOMAIN_ISSUE:
      if (addToObjectStore) {
        obj = await objectStore.processIssueItem(aisObject);
      } else {
        obj = IssueModel.create(aisObject, objectStore && objectStore.rootStore);
      }
      break;
    case DOMAIN_TEXT:
      if (addToObjectStore) {
        obj = await objectStore.processTextItem([aisObject], version, {}, { loadKinds: false });
      } else {
        obj = TextStore.textItemFabric(
          { ...aisObject,
            validations: {}
          },
          version,
          textStore || objectStore
        );
        if (textStore) {
          textStore.addItem(obj);
          obj.init(aisObject);
        }
      }
      break;
    case DOMAIN_BINDER:
      if (addToObjectStore) {
        obj = await objectStore.processRelationItem(aisObject);
      } else {
        obj = RelationModel.create(aisObject);
      }
      
      break;
    case DOMAIN_USERS:
      obj = UserModel.create(aisObject, objectStore && objectStore.rootStore.userStore);
      break;
    case DOMAIN_KINDS:
      obj = new KindModel(aisObject,  objectStore && objectStore.rootStore.kindsStore);
      break;
    default:{
      // throw new Error(`No domain specified - ${domain} in class="${klass}"`);
      console.error(`Не определен домен - ${domain} в class="${klass}"`, aisObject);
    }      
  }
  return obj;
};


/**
 * Создать url до АИС объекта, чтобы url можно было перейти к АИС объекту
 * @param {AisObject} object  АИС объект
 * @returns 
 */
const generateAisObjectUrl = async(object) => {
  let data = {
    uid:    object.uid,
    domain: object.domain
  };

  switch (object.class) {
    case CLS_LIBRARY_TEXT_MATERIAL:
      data = {
        uid:    object.editable,
        domain: DOMAIN_TEXT
      };
      break;
    case CLS_LIBRARY_TEXT_VERSION:
      data = {
        uid:     object.parent && object.parent.editable,
        domain:  DOMAIN_TEXT,
        version: object.number
      };
      break;
    case CLS_LIBRARY_REPOSITORY:
      data = {
        uid:    `${generateRepoId(object.editable)}/`,
        domain: DOMAIN_REPO
      };
      break;
  }

  const url = new URL(window.location.origin);
  const props = {};
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object") {
      const obj = data[key];
      Object.keys(obj).forEach((k) => {
        if (Array.isArray(obj[k])) {
          props[`$${key}$${k}[]`] = JSON.stringify(obj[k]);
        } else {
          props[`$${key}$${k}`] = obj[k];
        }
      });
    } else if (Array.isArray(data[key])) {
      props[`${key}[]`] = JSON.stringify(data[key]);
    } else  {
      props[key] = data[key];
    }
  });
  url.search = new URLSearchParams(props);
  return url;
};

const parseUrlSearchParams = (paramsString) => {
  if (!paramsString) {
    return;
  }
  const searchParams = new URLSearchParams(paramsString);
  const res = {};
  Array.from(searchParams.entries()).forEach(([key, value]) => {
    const regexp = /^\$(\w+)\$(\w+(\[\])?)/gi;
    
    if (regexp.test(key)) {
      const matches = key.split(regexp);
      if (Array.isArray(matches) && matches.length >= 3) {
        const objParamsKey = matches[1];
        if (!res[objParamsKey]) {
          res[objParamsKey] = {};
        }

        const childKey = matches[2];
        const [k, v] = parseArray(childKey, value);
        res[objParamsKey][k] = v;
      }
    } else {
      const [k, v] = parseArray(key, value);
      res[k] = v;
    }
  });

  return res;
};

/**
 * Проверяем ключ и значение на представление массив
 * @param {String} initKey ключ значения. Если Ключ имеет в конце [], значит передан массив
 * @param {String} initValue значение
 * @returns {Array[String, String]} возвращается преобразованный ключ и значение
 */
const parseArray = (initKey, initValue) => {
  let key = initKey;
  let value = initValue;
  if (key.endsWith("[]")) { // признак массива
    key = key.slice(0, -2);  // приводим ключ к нормальному виду
    try {
      value = JSON.parse(initValue);
    } catch (ex) {
      console.error(ex);
    }
  }
  if (key === "version" && value) {
    try {
      value = parseInt(value);  
    } catch (ex) {
      console.error(`Parse varrsion error: ${ex.message}`);
    }
  }
  return [key, value];
};

export  default processComposerAisObject;

export {
  processComposerAisObject,
  processAisObject,
  generateAisObjectUrl,
  parseUrlSearchParams
};
